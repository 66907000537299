import { lightboxImages, showLightbox } from '@/stores';
import { useStore } from '@nanostores/react';
import LightboxOriginalGangsta from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';

export default function Lightbox() {
  const open = useStore(showLightbox);
  const images = useStore(lightboxImages);

  return (
    <LightboxOriginalGangsta
      open={open}
      slides={images}
      close={() => showLightbox.set(false)}
      controller={{
        closeOnBackdropClick: true,
      }}
      styles={{ root: { zIndex: 10001 } }}
      plugins={[Zoom]}
      carousel={{ preload: 5 }}
      animation={{
        swipe: 0,
      }}
    />
  );
}
